import { defaultFormSchema } from "./defaultFormSchema";
import { FormSchema } from "./schema";

const voteFormSchema: FormSchema = {
  programId: {
    type: "programSearch",
    label: "Program",
    description: "The program to which this report applies.",
    required: true,
  },
  tier: {
    type: "select",
    label: "Tier",
    description: "In which tier does this program belong?",
    required: true,
  },
  comment: {
    type: "string",
    label: "Comment",
    description: "Any additional information about your vote.",
    placeholder: "eg. Supportive environment but long hours",
  },
  graduateType: defaultFormSchema.graduateType,
  anonymous: {
    type: "boolean",
    label: "Post Anonymously",
    description: "An anonymous post is not linked to your user profile.",
    defaultValue: false,
  },
};

export default voteFormSchema;
